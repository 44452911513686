import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Img from 'gatsby-image';

import Overview from '../../components/overview';
import Layout from '../../components/layout';

export default ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>Basic Pizza Sauce</title>
      </Helmet>
      <div>
        <h1>Basic Pizza Sauce</h1>
        <Img fluid={data.file.childImageSharp.fluid} />
        <Overview>
          This recipes is intended as a starting place for dialing in your pizza
          sauce. It is intentionally vague because I really believe you should
          make the sauce to your personal taste preferences. Some brands of
          tomatoes will need more salt than others. Slowly dial in your salt
          amount until you get it to where you like it, and remember that cheese
          will bring saltiness to the pizza as well.
        </Overview>
        <h2>Ingredients</h2>
        <table className="table is-bordered is-hoverable">
          <thead>
            <tr>
              <th>Ingredient</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Crushed/Ground Tomatoes</td>
              <td>28 oz Can</td>
            </tr>
            <tr>
              <td>Minced/Pressed Garlic</td>
              <td>1 Clove</td>
            </tr>
            <tr>
              <td>Basil</td>
              <td>2 Whole Leaves</td>
            </tr>
            <tr>
              <td>Extra Virgin Olive Oil</td>
              <td>A Splash / To Taste</td>
            </tr>
            <tr>
              <td>Salt</td>
              <td>To Taste</td>
            </tr>
          </tbody>
        </table>
        <h1>Ingredient Considerations</h1>
        <h2>Tomatoes</h2>
        <p>
          Here are some brands of tomatoes that I enjoy, most of them are easy
          to obtain online or in a local Italian food store:
        </p>
        <ul>
          <li>Bianco DiNapoli Organic Crushed Tomatoes</li>
          <li>Sclafani Crushed Tomatoes (From NJ, not Italy)</li>
          <li>
            Stanislaus 7/11 Ground Tomatoes (No, they don't sell these at
            7/11&nbsp;
            <span role="img" aria-label="Winking Emoji">
              😉
            </span>
            )
          </li>
          <li>Jersey Fresh Crushed Tomatoes</li>
        </ul>
        <h1>Instructions</h1>
        Add all ingredients to a bowl or container and mix well, do not blend.
        Season with salt to taste. This sauce is best if made at least one day
        in advance. It can be kept sealed in the fridge for up to 2 weeks.
        <h1>Variations</h1>
        <p>
          Depending on the pizza you are making you may want to try some
          variations, for example:
        </p>
        <ul>
          <li>For NY pizza, try adding some dried oregano.</li>
          <li>For Sicilan pies, add some tomato paste.</li>
          <li>For a Grandma pie, add some finely minced anchovies.</li>
          <li>For extra umami, add grated Pecorino and anchovies.</li>
        </ul>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    file(relativePath: { eq: "recipes/pizzaSauce/main.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
